import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { APIClient } from '../api-client';
import { ClientEntry } from './ClientEntry';
import { Counter } from './stats/Counter';

export const PlayCounterImpl: React.FC = () => {
  const statsQuery = useQuery({
    queryKey: ['globalStats'],
    queryFn: () => new APIClient().getGlobalStats(),
  });

  const playCount = statsQuery.data?.total_solve_count ?? -1;
  return <Counter value={playCount} />;
};

export const PlayCounter = () => {
  return (
    <ClientEntry>
      <div className="w-30 text-normal mx-auto flex flex-col items-center gap-1 font-mono">
        <PlayCounterImpl />
        <div className="text-xs opacity-80">Games Played!</div>
      </div>
    </ClientEntry>
  );
};
